<template>

    <div class="w-100">
        <!-- Header -->
        <div class="text-center mb-5">
            <p class="mb-3">
                <i class="fa fa-2x fa-circle-notch text-primary-light"></i>
            </p>
            <h1 class="fw-bold mb-2">
                Buat Akun
            </h1>
            <p class="fw-medium text-muted">
                Mulai terbitkan bukumu sekarang.<br/> Sudah punya akun? <a href="/login">Masuk</a>
            </p>
        </div>

        <div class="row g-0 justify-content-center">
            <div class="col-sm-8 col-xl-8">
                <form class="js-validation-signup" @submit.prevent="Register">
                    <div class="mb-4">
                        <div class="row">
                            <div class="col-12 col-md-6 mb-4">
                                <select class="form-select form-control form-control-lg form-control-alt" required v-model="form.type">
                                    <option value="" selected disabled>Pilih Jenis</option>
                                    <option value="PT">Perseroan Terbatas (PT)</option>
                                    <option value="CV">Commanditaire Vennonstchap (CV)</option>
                                    <option value="UD">Usaha Dagang (UD)</option>
                                    <option value="Perguran Tinggi">Perguruan Tinggi</option>
                                    <option value="Yayasan">Yayasan</option>
                                    <option value="Self Publishing">Self Publishing</option>
                                    <option value="Affiliator">Affiliator</option>
                                </select>
                            </div>
                            <div class="col-12 col-md-6 mb-4">
                                <input type="text" class="form-control form-control-lg form-control-alt py-3" placeholder="Nama Penerbit" required v-model="form.name">
                            </div>
                        </div>
                        <div class="form-title mb-2"><strong>Detail Alamat Penerbit</strong></div>
                        <div class="row">
                            <div class="col-12 col-md-6 mb-4">
                                <select v-model="form.address.provinsi" class="form-select form-control form-control-lg form-control-alt" required v-on:change="getKota" :disabled="loading.provinsi">
                                    <option value="" selected disabled>Provinsi</option>
                                    <option v-for="provinsi in data.provinces" :key="provinsi.province_id" :value="provinsi.province_id">{{provinsi.province}}</option>
                                </select>
                            </div>
                            <div class="col-12 col-md-6 mb-4">
                                <select v-model="form.address.kota" class="form-select form-control form-control-lg form-control-alt" required :disabled="loading.kota" v-on:change="getKecamatan">
                                    <option value="" selected disabled>Kota/Kabupaten</option>
                                    <option v-for="(kota, index) in data.cities" :key="index" :value="kota.city_id">{{kota.type}} {{kota.city_name}}</option>
                                </select>
                            </div>
                            <div class="col-12 col-md-6 mb-4">
                                <select class="form-select form-control form-control-lg form-control-alt" required :disabled="loading.kecamatan" v-model="form.address.kecamatan">
                                    <option value="" selected disabled>Kecamatan</option>
                                    <option v-for="(kecamatan, index) in data.kecamatans" :key="index" :value="kecamatan.subdistrict_id">
                                        {{kecamatan.subdistrict_name}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-12 col-md-6 mb-4">
                                <input type="text" class="form-control form-control-lg form-control-alt py-3" placeholder="Kelurahan" required v-model="form.address.kelurahan">
                            </div>
                            <div class="col-12 col-md-12 mb-4">
                                <textarea v-model="form.address.detail" class="form-control form-control-alt" rows="4" placeholder="Alamat Lengkap" required></textarea>
                            </div>
                        </div>
                    </div>
                    
                    <div class="form-title mb-2"><strong>Detail Alamat Penerbit</strong></div>

                    <div class="row">
                        <div class="col-12 col-md-4 mb-4">
                            <input type="email" v-model="form.email" class="form-control form-control-lg form-control-alt py-3" placeholder="Email" required>
                        </div>
                        <div class="col-12 col-md-4 mb-4">
                            <input type="password" v-model="form.password" class="form-control form-control-lg form-control-alt py-3" placeholder="Password" required>
                        </div>
                        <div class="col-12 col-md-4 mb-4">
                            <input type="password" v-model="form.password_confirmation" class="form-control form-control-lg form-control-alt py-3" placeholder="Ulangi Password" required>
                        </div>
                        <div class="col-12 col-md-6 mb-4">
                            <input type="text" class="form-control form-control-lg form-control-alt py-3" placeholder="Penanggung Jawab" required v-model="form.penanggung_jawab">
                        </div>
                        <div class="col-12 col-md-6 mb-4">
                            <input type="text" class="form-control form-control-lg form-control-alt py-3" placeholder="No HP" required v-model="form.phone">
                        </div>
                    </div>
                    
                    <div class="text-center">
                        <button type="submit" class="btn btn-lg btn-alt-success" :disabled="loading.submit">
                            <i class="fa fa-fw fa-plus me-1 opacity-50"></i> Daftar
                        </button>
                    </div>
                </form>
            </div>
        </div>
        <!-- END Sign Up Form -->
    </div>

</template>

<script>

import axios from 'axios'
export default {
    components: {

    },

    data: () => ({
        hasVisiblePassword: false,
        form: {
            type: '',
            name: '',
            email: '',
            password: '',
            password_confirmation: '',
            phone: '',
            penanggung_jawab: '',

            address: {
                provinsi: '',
                kota: '',
                kecamatan: '',
                kelurahan: '',
                detail: ''
            },
        },

        loading: {
            provinsi: false,
            kota: false,
            kecamatan: false,

            submit: false
        },

        data: {
            provinces: [],
            cities: [],
            kecamatans: []
        }

    }),

    mounted() {

        this.Provinsi()

    },

    methods: {

        Provinsi() {

            this.loading.provinsi = true

            axios.get('provinsi', {
                headers: {
                    "Access-Control-Allow-Headers": "*",
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            })
                .then(response => {
                    this.data.provinces = response.data.data
                    console.log(this.data.provinces)
                })
                .catch(error => console.log(error))
                .finally(() => (this.loading.provinsi = false))
        },

        getKota() {

            this.loading.kota = true

            axios.get('kota/' + this.form.address.provinsi, {
                headers: {
                    "Access-Control-Allow-Headers": "*",
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            })
                .then(response => {
                    this.data.cities = response.data.data
                    console.log(this.data.cities)
                })
                .catch(error => console.log(error))
                .finally(() => (this.loading.kota = false))

        },

        getKecamatan() {

            this.loading.kecamatan = true

            axios.get('kecamatan/' + this.form.address.kota, {
                headers: {
                    "Access-Control-Allow-Headers": "*",
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            })
                .then(response => {
                    this.data.kecamatans = response.data.data
                    console.log(this.data.kecamatans)
                })
                .catch(error => console.log(error))
                .finally(() => (this.loading.kecamatan = false))

        },

        Register() {
            this.loading.submit = true

            return new Promise(() => {
                axios.post('register', {

                    type: this.form.type,
                    name: this.form.name,
                    email: this.form.email,
                    phone: this.form.phone,
                    penanggung_jawab: this.form.penanggung_jawab,
                    password: this.form.password,
                    password_confirmation: this.form.password_confirmation,
                    provinsi: this.form.address.provinsi,
                    kota: this.form.address.kota,
                    kecamatan: this.form.address.kecamatan,
                    kelurahan: this.form.address.kelurahan,
                    address: this.form.address.detail,

                }).then(response => {

                    this.$router.push({ name: 'login' })
                    this.openNotification('top-right', 'success', '10000', 'Pendaftaran Berhasil', response.data.message);
                    this.loading.submit = false

                })

                    .catch(error => {
                        this.openNotification('top-right', 'danger', '10000', 'Autentikasi Gagal', error.response.data.message);
                        this.loading.submit = false
                    })

            })
        },

        openNotification(position = null, color, duration, title, message) {
            this.$vs.notification({
                color,
                position,
                duration,
                title: title,
                text: message
            })
        },

    }
}

</script>

<style scoped>
@import '../../../public/oneui/assets/css/oneui.min.css';
</style>
